<template>
  <div
    class="modal fade"
    id="kt_modal_add_email_list"
    tabindex="-1"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <div class="modal-header flex-stack">
          <h2>Add New List</h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <svg
                fill="none"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                ></rect>
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                ></rect>
              </svg>
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y pt-10 pb-15 px-lg-17">
          <div :class="`${userInput}`">
            <p class="text-gray-400 fs-5 fw-bold mb-10">
              Select how you want to create a list
            </p>
            <div class="pb-10">
              <input
                type="radio"
                class="btn-check"
                name="auth_option"
                id="kt_modal_two_factor_authentication_option_1"
                value="paste"
                checked
                v-model="selectedInput"
              />
              <label
                class="
                  btn btn-outline btn-outline-dashed btn-outline-default
                  p-7
                  d-flex
                  align-items-center
                  mb-5
                "
                for="kt_modal_two_factor_authentication_option_1"
              >
                <span class="svg-icon svg-icon-4x me-4">
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                      fill="black"
                    ></path>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      opacity="0.3"
                      d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
                <span class="d-block fw-bold text-start">
                  <span class="text-dark fw-bolder d-block fs-3"
                    >Paste Email Addresses</span
                  >
                  <span class="text-gray-400 fw-bold fs-6">
                    You can paste upto 5000 email addresses
                  </span>
                </span>
              </label>
              <input
                type="radio"
                class="btn-check"
                name="auth_option"
                id="kt_modal_two_factor_authentication_option_2"
                value="upload"
                v-model="selectedInput"
              />
              <label
                class="
                  btn btn-outline btn-outline-dashed btn-outline-default
                  p-7
                  d-flex
                  align-items-center
                "
                for="kt_modal_two_factor_authentication_option_2"
              >
                <span class="svg-icon svg-icon-4x me-4">
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      opacity="0.3"
                      d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                      fill="black"
                    ></path>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
                <span class="d-block fw-bold text-start">
                  <span class="text-dark fw-bolder d-block fs-3">
                    Upload a File
                  </span>
                  <span class="text-gray-400 fw-bold fs-6">
                    Upload a CSV with maximum 25000 emails
                  </span>
                </span>
              </label>
            </div>
            <button class="btn btn-primary w-100" @click="showInputForm">
              Continue
            </button>
          </div>
          <form @submit.prevent="submitForm">
            <div :class="`${showPastePanel}`" data-kt-element="apps">
              <div class="row col-12 mt-2 mb-5">
                <label for="list-name" class="mb-2"> List Name </label>
                <input
                  class="form-control form-control-solid"
                  placeholder="Enter a list name"
                  required
                  v-model="list_name"
                />
              </div>
              <div class="row col-12">
                <label for="domains" class="mb-2"> Email Addresses </label>
                <textarea
                  v-model="emails"
                  class="form-control form-control-solid"
                  rows="6"
                  id="domains"
                  placeholder="john@example.com
jenna@domain.com"
                ></textarea>
              </div>
              <div class="d-flex flex-center mt-3">
                <button
                  type="reset"
                  @click="resetPanel"
                  class="btn btn-white me-3"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  data-kt-element="apps-submit"
                  :disabled="isDisabled"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> Submit </span>
                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </div>
          </form>
          <form @submit.prevent="submitFile">
            <div :class="`${showCsvUpload}`" data-kt-element="sms">
              <div class="row col-12 mt-2 mb-5">
                <label for="list-name" class="mb-2"> List Name </label>
                <input
                  class="form-control form-control-solid"
                  id="list-name"
                  placeholder="Enter a list name"
                  required
                  v-model="list_name"
                />
              </div>
              <div class="row col-12">
                <label
                  for="uploadFile"
                  class="
                    btn btn-outline btn-outline-dashed btn-outline-default
                    p-7
                    d-flex
                    align-items-center
                    mb-2
                  "
                >
                  <input
                    type="file"
                    style="display: none"
                    ref="csvUploader"
                    @click="resetCsvUploader"
                    @change="uploadFile"
                    id="uploadFile"
                    accept=".csv"
                  />
                  <span class="svg-icon svg-icon-3x me-5">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                        fill="black"
                      ></path>
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        opacity="0.3"
                        d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  <span class="d-block fw-bold text-start">
                    <span
                      class="fw-bolder d-block fs-4 mb-2"
                      :class="`${textColor}`"
                      >{{ fileMessage }}</span
                    >
                    <span class="text-gray-400 fw-bold fs-6">{{
                      fileSuggestion
                    }}</span>
                  </span>
                </label>
                <a href="https://api.etnu.com/sample.csv"
                  >Click here to download a sample CSV file</a
                >
              </div>
              <div class="fv-row mb-7 mt-5">
                <div class="d-flex flex-stack">
                  <div class="me-5">
                    <label class="fs-6 fw-bold">
                      Does you file contains a header?
                    </label>
                    <div class="fs-7 fw-bold text-muted">
                      Check this if first row is not an actual data
                    </div>
                  </div>
                  <label
                    class="
                      form-check form-switch form-check-custom form-check-solid
                    "
                  >
                    <input
                      class="form-check-input"
                      name="billing"
                      type="checkbox"
                      id="kt_modal_add_customer_billing"
                      v-model="has_header"
                    />
                    <span
                      class="form-check-label fw-bold text-muted"
                      for="kt_modal_add_customer_billing"
                    >
                      Yes
                    </span>
                  </label>
                </div>
              </div>
              <div class="fv-row mb-7 mt-5">
                <div class="d-flex flex-stack">
                  <div class="me-5">
                    <label class="fs-6 fw-bold">
                      Which column of your file is an actual email
                    </label>
                    <div class="fs-7 fw-bold text-muted">
                      Start counting from the left
                    </div>
                  </div>
                  <div class="col-2">
                    <input
                      class="form-control"
                      name="billing"
                      type="number"
                      id="kt_modal_row_number"
                      v-model="email_column"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-center">
                <button @click="resetPanel" class="btn btn-white me-3">
                  Cancel
                </button>
                <button
                  type="submit"
                  data-kt-element="sms-submit"
                  class="btn btn-primary"
                  :disabled="isDisabled"
                  :data-kt-indicator="isLoading"
                >
                  <span class="indicator-label"> Submit </span>
                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  name: "EmailVerifierInput2",
  data: () => ({
    isDisabled: false,
    fileSuggestion: "Should be in CSV format",
    textColor: "text-dark",
    fileMessage: "Select a File",
    selectedInput: "paste",
    userInput: null,
    showPastePanel: "d-none",
    showCsvUpload: "d-none",
    list_name: null,
    emails: "",
    validEmails: "",
    email_column: null,
    has_header: 0,
    upload_type: null,
    csvFile: null,
    invalidEmails: [],
    formData: null,
    isLoading: "off",
  }),
  methods: {
    showInputForm() {
      console.log("user selected ", this.selectedInput);
      if (this.selectedInput === "paste") {
        this.userInput = "d-none";
        this.showPastePanel = null;
      }
      if (this.selectedInput === "upload") {
        this.userInput = "d-none";
        this.showCsvUpload = null;
      }
    },
    resetPanel() {
      this.userInput = null;
      this.showPastePanel = "d-none";
      this.showCsvUpload = "d-none";
    },
    submitForm() {
      this.isDisabled = true,
      this.isLoading = "on";
      if (!this.emails) {
        Swal.fire({
          text: "Please provide email address",
          icon: "warning",
        });
        this.isDisabled = false,
        this.isLoading = "off";
        return;
      }
      if (this.emails && this.checkEmails()) {
        Swal.fire({
          text: "Please remove invalid email address",
          icon: "warning",
        });
        this.isDisabled = false,
        this.isLoading = "off";
        return;
      }
      this.formData = new FormData();
      this.formData.append("list_name", this.list_name.trim());
      this.formData.append("upload_type", this.selectedInput);
      this.formData.append("emails", this.validEmails);
      this.submitToAPI(this.formData);
    },
    resetCsvUploader() {
      this.$refs.csvUploader.value = "";
      this.textColor = "text-dark";
      this.fileMessage = "Select a file";
      this.fileSuggestion = "Should be in CSV format";
    },
    uploadFile() {
      this.csvFile = this.$refs.csvUploader.files[0];
      this.fileMessage = this.csvFile.name;
    },
    submitFile() {
      this.isDisabled = true,
      this.isLoading = "on";
      if (!this.csvFile) {
        this.fileSuggestion = "Should be in CSV format";
        this.fileMessage = "Please select a csv file";
        this.textColor = "text-danger";
        this.isDisabled = false,
        this.isLoading = "off";
        return;
      }
      this.formData = new FormData();
      this.formData.append("list_name", this.list_name.trim());
      this.formData.append("upload_type", this.selectedInput);
      this.formData.append("emails_file", this.csvFile);
      this.formData.append("has_header", this.has_header ? 1 : 0);
      this.formData.append("email_column", this.email_column);
      this.submitToAPI(this.formData);
    },
    checkEmails() {
      this.validEmails = this.emails.trim().replace(/\n\s*\n/g, "\n");
      const uEmails = this.validEmails.split(/\r?\n/); // split string by new line
      const emailRegex = /^\S+@\S+\.\S+$/;
      this.invalidEmails = uEmails.filter((email) => !emailRegex.test(email));
      return this.invalidEmails.length;
    },

    submitToAPI(formData) {
      this.isDisabled = true,
      store.dispatch("EmailVerifier2/save", formData).then((response) => {
        this.isDisabled = false,
        this.isLoading = "off";
        this.csvFile = null;
        this.list_name = null;
        this.emails = null;
        this.email_column = null;
        this.has_header = 0;
        Swal.fire({
          text: response.message,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      });
    },
  },
};
</script>
<style scoped></style>
